import React from 'react';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';

import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import {
  getUTCDateTime,
} from 'utils';
import styles from './styles.module.css';

const columns = [
  {
    name: 'Item',
    key: 'item',
    size: 'small',
  }, {
    name: 'Value',
    key: 'value',
    size: 'medium',
  }, {
    name: 'Changed',
    key: 'changed',
    size: 'small',
  },
];

const ConfigrationHistory = (props) => {
  const {
    rActiveAircraft,
    goBack,
    match: { params },
  } = props;

  const configTypeId = params.id;
  const [config] = rActiveAircraft?.configuration.filter((c) => c.configuration_type.id === configTypeId);

  const data = [];
  const configHistory = rActiveAircraft.getConfigHistory(config.configuration_type);
  configHistory.forEach((h) => {
      data.push({
        item: config.configuration_type.value.name,
        value: h.value,
        changed: `${getUTCDateTime(h.dt)} UTC`,
      })
    });

  return (
    <Row className={styles.root} noGutters>
      <Col className="h-100">
        <Row className={styles.headerRow} noGutters>
          <Col className={styles.headerContainer}>
            {`${rActiveAircraft.tail} - Features history`}
          </Col>
          <Col md="auto">
            <Button className="button_foc" onClick={() => goBack()}>Close</Button>
          </Col>
        </Row>
        <Row className={styles.tableRow} noGutters>
          <Col className={styles.tableContainer}>
            <SkyPulseTable
              columns={columns}
              rows={data}
              title="Configurable features"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const state = (state) => {
  return ({
    rActiveAircraft: state.activeAircraft,
  });
};

const actions = {
  goBack,
};

export default connect(state, actions)(ConfigrationHistory);
