import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SYSTEM } from 'constants';

import {
  EVENT_TYPE_FLIGHT_CLOSE,
  EVENT_TYPE_FLIGHT_OPEN,
  EVENT_TYPE_FLIGHT_PHASE_LANDING,
  EVENT_TYPE_FLIGHT_PHASE_TAKEOFF,
  EVENT_TYPE_HARD_RESET,
  EVENT_TYPE_OFFLINE,
  EVENT_TYPE_RECONNECT,
  EVENT_TYPE_SOFT_RESET,
  EVENT_TYPE_WAP_PRIMARY,
  EVENT_TYPE_WAP_SECONDARY,
  LRU_TYPES,
  NOT_APPLICABLE,
} from 'constants';
import { Flight } from 'models';
import { getUTCTime } from 'utils';

import { Button, Col, Row } from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import FlightEventsOverlay from './FlightEventsOverlay';

import styles from './styles.module.css';

const columns =
  [
    {
      name: 'UTC',
      key: 'utc',
      sortable: true,
      className: styles.utcColumn,
      dataCyClassName: 'Flights-FlightEventsOverviewtTableColumn-Utc'
    },
    {
      name: 'LRU',
      key: 'lru',
      sortable: true,
      className: styles.lruTypeColumn,
      dataCyClassName: 'Flights-FlightEventsOverviewtTableColumn-Lru'
    },
    {
      name: 'Pos',
      key: 'position',
      sortable: true,
      className: styles.lruPositionColumn,
      dataCyClassName: 'Flights-FlightEventsOverviewtTableColumn-Pos'
    },
    {
      name: 'Event',
      key: 'event',
      sortable: true,
      className: styles.messageColumn,
      dataCyClassName: 'Flights-FlightEventsOverviewtTableColumn-Event'
    }
  ];

const FlightEventsPanel = (props) => {
  const { flight, showOverlayButton, selectedMode } = props;
  const { events } = flight;

  const [flightEventsOverlayOpen, setFlightEventsOverlayOpen] = useState(false);

  const commonEvents = [
    EVENT_TYPE_FLIGHT_CLOSE,
    EVENT_TYPE_FLIGHT_OPEN,
    EVENT_TYPE_FLIGHT_PHASE_LANDING,
    EVENT_TYPE_FLIGHT_PHASE_TAKEOFF,
  ]

  const getFilteredEvents = () => {
    switch (selectedMode) {
      case 'faults':
        return events.filter((event) => event.event_type.value.is_fault || commonEvents.includes(event.event_type));
      case 'wap':
        return events.filter((event) => event.event_type.id === EVENT_TYPE_WAP_PRIMARY.id
          || event.event_type.id === EVENT_TYPE_WAP_SECONDARY.id
          || commonEvents.includes(event.event_type)
        );
      case 'seatReset':
        return events.filter((event) => event.event_type.id === EVENT_TYPE_HARD_RESET.id
            || event.event_type.id === EVENT_TYPE_SOFT_RESET.id
            || commonEvents.includes(event.event_type)
        );
      case 'disconnected':
        return events.filter((event) => event.event_type.id === EVENT_TYPE_OFFLINE.id
            || event.event_type.id === EVENT_TYPE_RECONNECT.id
            || commonEvents.includes(event.event_type)
        );
      default:
        return events;
    }
  }

  const filteredEvents = getFilteredEvents().map((event) => {
    const { value: { default_message: defaultMessage } } = event.event_type;
    return (
      {
        utc: { display: getUTCTime(event.created), sort: event.created },
        lru: (event.lru_type) ? LRU_TYPES[event.lru_type.id] : SYSTEM,
        position: event.lru_position || NOT_APPLICABLE,
        event: event.message || defaultMessage,
      }
    );
  });

  return (
    <>
      <Row className={styles.root} data-cy-id="Flights-EventsPanel" noGutters>
        <Col className="h-100">
          <SkyPulseTable
            title={
              <Row>
                <Col>Flight events overview</Col>
                {
                  showOverlayButton ?
                    <Col md="auto">
                      <Button
                        data-cy-id="Flights-EventsPanel-OpenFlightEventsPanel"
                        className="button_foc"
                        onClick={() => setFlightEventsOverlayOpen(true)}
                      >
                        Open flight events
                      </Button>
                    </Col>
                    : null
                }
              </Row>
            }
            defaultSort="utc"
            defaultSortState="ascending"
            columns={columns}
            rows={filteredEvents}
          />
        </Col>
      </Row>
      {
        !showOverlayButton
          ? null
          : <FlightEventsOverlay
            isOpen={flightEventsOverlayOpen}
            onCancel={() => setFlightEventsOverlayOpen(false)}
          />
      }
    </>
  );
}

FlightEventsPanel.defaultProps = {
  showOverlayButton: true
}

FlightEventsPanel.propTypes = {
  flight: PropTypes.instanceOf(Flight),
  selectedMode: PropTypes.string,
  showOverlayButton: PropTypes.bool,
}

export default FlightEventsPanel;
