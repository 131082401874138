import { DFPAPILambdaJSONResponse } from 'classes';

export function getConfiguration(data = {}) {
  const result = Object.assign({}, {
    "log_level": "debug",
    "ff_global_auth": "false",
    "ff_kiosk_mode": "true",
    "ff_lrus_and_faults": "true",
    "ff_reports": "true",
    "ff_rssi_heatmap": "true",
    "maintenance_mode": "false"
  }, data);

  return new DFPAPILambdaJSONResponse(result);
}
