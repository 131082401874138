import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  AIRCRAFT_STATUS_OFFLINE,
  FLIGHT_STATUS_IN_AIR,
  FLIGHT_STATUS_UNKONWN,
  IATA_UNKNOWN,
  NOT_APPLICABLE,
} from 'constants';
import { Aircraft } from 'models';

import {
  Col,
  Row,
} from 'react-bootstrap'
import SkyPulseTable from 'components/SkyPulseTable';

import styles from './styles.module.css';

const columns = [
  {
    name: '',
    key: 'status',
    className: styles.statusColumn,
    dataCyClassName: 'Dashboard-AircraftListTableColumn-StatusIcon'
  },
  {
    name: 'Tail',
    key: 'aircraft_tail',
    size: 'small',
    dataCyClassName: 'Dashboard-AircraftListTableColumn-Tail'
  },
  {
    name: 'Location',
    key: 'flight_leg',
    size: 'medium',
    dataCyClassName: 'Dashboard-AircraftListTableColumn-Location'
  },
  {
    name: 'Status',
    key: 'flight_status',
    size: 'large',
    dataCyClassName: 'Dashboard-AircraftListTableColumn-Status'
  }
];

const getStatusClass = (status) => {
  switch (status) {
    case '1':
      return styles.flightOperational;
    case '2':
      return styles.flightDegraded;
    case '3':
      return styles.flightUnhealthy;
    case '4':
    default:
      return styles.flightOffline;
  }
}

// Based of staus id
const sortingOrder = {
  '3': 1, // Unhealthy
  '2': 2, // Degraded
  '1': 3, // Operational
  '4': 4, // Offline
}

const AircraftListPanel = (props) => {
  const {
    activeAircraft,
    aircraftList,
    onAircraftSelect,
  } = props;

  const onRowClick = (id) => {
    onAircraftSelect(id);
  }

  // Build data when aircraft is updated
  let data = aircraftList
    .sort((a, b) => {
      if (sortingOrder[a.status.id] > sortingOrder[b.status.id]) return 1;
      if (sortingOrder[a.status.id] < sortingOrder[b.status.id]) return -1;
      return 0;
    })
    .sort((a, b) => {
      if (a.status.id === b.status.id) {
        if (a.tail > b.tail) return 1;
        if (a.tail < b.tail) return -1;
      }
      return 1;
    })
    .map((aircraft) => {
      const {
        id: aircraftId,
        is_connected: isConnected,
        status,
        tail,
      } = aircraft;

      const aircraftStatusId = (aircraft.status) ? aircraft.status.id : null;
      const selected = !activeAircraft ? false : aircraftId === activeAircraft.id;

      let depIATA = IATA_UNKNOWN;
      let desIATA = IATA_UNKNOWN;
      let flightStatusName = FLIGHT_STATUS_UNKONWN;

      const flight = aircraft.getCurrentFlight();
      if (flight) {
        if (flight.departure_airport) {
          depIATA = flight.departure_airport.value.iata;
        }

        if (flight.destination_airport) {
          desIATA = flight.destination_airport.value.iata;
        }

        if (flight.status) {
          flightStatusName = flight.status.value.name;
        }
      }

      //  if the aircraft is not offline
      //    if current flight is open
      //      show full flight route if available
      //    else
      //      show destination iata if available
      //  else
      //    show --
      const flightRoute = (status.id !== AIRCRAFT_STATUS_OFFLINE.id)
        ? (flight && flight.status && flight.status.id === FLIGHT_STATUS_IN_AIR.id)
          ? `
            ${(depIATA !== IATA_UNKNOWN) ? depIATA : ''}
            ${(depIATA === IATA_UNKNOWN || desIATA === IATA_UNKNOWN) ? '' : ' - '}
            ${(desIATA !== IATA_UNKNOWN) ? desIATA : ''}
            `
          : (desIATA !== IATA_UNKNOWN) ? desIATA : null
        : NOT_APPLICABLE;

      const flightStatus = isConnected
        ? flightStatusName
        : 'Disconnected';

      return (
        {
          aircraft_tail: tail,
          flight_leg: flightRoute,
          flight_status: flightStatus,
          id: aircraftId,
          status: (<div
            data-cy-id={`Dashboard-AircraftListStatusIcon-${status.value.name}`}
            className={classnames(
              styles.statusIcon,
              getStatusClass(aircraftStatusId),
              selected ? styles.statusIconSelected : null
            )}
          >
            {!isConnected ? <div data-cy-id="Dashboard-AircraftListIconDisconnected" className={styles.disconnectedLine} /> : null}
          </div>)
        });
    });

  return (
    <Row className="h-100" data-cy-id="Dashboard-AircraftListPanel" noGutters>
      <Col className="h-100">
        <SkyPulseTable
          tableClassname={styles.table}
          columns={columns}
          rows={data}
          onRowClick={onRowClick}
          selectedId={(activeAircraft) ? activeAircraft.id : null}
        />
      </Col>
    </Row>
  );
}

AircraftListPanel.propTypes = {
  activeAircraft: PropTypes.instanceOf(Aircraft),
  aircraftList: PropTypes.arrayOf(PropTypes.instanceOf(Aircraft)).isRequired,
  onAircraftSelect: PropTypes.func.isRequired,
}

export default AircraftListPanel;
