import React from 'react';
import PropTypes from 'prop-types';

import {
  Aircraft,
  LRU,
} from 'models';

import {
  Row,
  Col,
} from 'react-bootstrap';
import SkyPulseTable from 'components/SkyPulseTable';
import { LRU_TYPE_OVD } from 'constants';

import styles from './styles.module.css';

const ITUOverviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Tail'
  }, {
    name: 'LRU',
    key: 'lru',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Lru'
  }, {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Pos'
  }, {
    name: 'ITU ROM',
    key: 'itu_rom',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuRom'
  }, {
    name: 'ITU ISD',
    key: 'itu_isd',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuIsd'
  }, {
    name: 'ITU FSS',
    key: 'itu_fss',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-ItuIsd'
  }, {
    name: 'Map SW part',
    key: 'map_sw_part',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-MapSwPart'
  }, {
    name: 'Map assets SW part',
    key: 'map_assets_sw_part',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-MapAssetsSwPart'
  }, {
    name: 'MCU',
    key: 'mcu',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Mcu'
  }
];

const OVDOverviewCol = [
  {
    name: 'Tail',
    key: 'tail',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Tail'
  }, {
    name: 'LRU',
    key: 'lru',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Lru'
  }, {
    name: 'Pos',
    key: 'pos',
    size: 'tiny',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-Pos'
  }, {
    name: 'HW part',
    key: 'hw_part',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-HwPart'
  }, {
    name: 'HW serial',
    key: 'hw_serial',
    size: 'small',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-HwSerial'
  }, {
    name: 'FW version',
    key: 'fw_version',
    size: 'xsmall',
    dataCyClassName: 'LruDetailsOverlay-OverviewTableColumn-FwVersion'
  }
];

const PublicDisplays = (props) => {
  const { lruData, aircraft } = props;

  let mcuId;
  let mcuChannel;
  let lruType;
  if (aircraft) {
    const aircraftLopa = aircraft.lopa;
    const { public_displays: publicDisplays } = aircraftLopa;
    const selectedPD = publicDisplays.find((pd) => pd.id === lruData.position);
    lruType = selectedPD.lru_type;
    mcuId = selectedPD.power_supply;
    mcuChannel = selectedPD.power_supply_channel;
  }

  let overviewData;
  if (lruType.id === LRU_TYPE_OVD.id) {
    const latestLRUHistory = lruData.getLatestHistory();
    let fw_version;
    let hw_part;
    let hw_serial;
    if (latestLRUHistory?.software_version) {
      const { software_version: { CFW, FUB, SWB } } = latestLRUHistory;
      fw_version = SWB;
      hw_part = FUB;
      hw_serial = CFW;
    }
    overviewData = [
      {
        tail: aircraft?.tail,
        lru: lruData.lru_type.value,
        pos: lruData.position,
        hw_part: hw_part,
        hw_serial: hw_serial,
        fw_version: fw_version
      }
    ];
  } else {
    const latestLRUHistory = lruData.getLatestHistory();
    let itu_isd;
    let itu_fss;
    let itu_rom;
    let map_assets_sw_part;
    let map_sw_part;
    if (latestLRUHistory?.software_version) {
      const { FSS, ISD, MAP, MASA, ROM, } = latestLRUHistory.software_version;
      itu_fss = FSS;
      itu_isd = ISD;
      itu_rom = ROM;
      map_assets_sw_part = MASA;
      map_sw_part = MAP;
    }

    overviewData = [
      {
        tail: aircraft?.tail,
        lru: lruData.lru_type.value,
        pos: lruData.position,
        itu_rom: itu_rom,
        itu_isd: itu_isd,
        itu_fss: itu_fss,
        map_sw_part: map_sw_part,
        map_assets_sw_part: map_assets_sw_part,
        mcu: `MCU ${mcuId}-J${mcuChannel}`
      }
    ];
  }

  return (
    <Row className={styles.overviewContainer} noGutters>
      <Col>
        <Row>
          <Col className={styles.title}>Overview</Col>
        </Row>
        <Row className={styles.overviewTable} noGutters>
          <Col>
            <SkyPulseTable
              columns={lruType.id === LRU_TYPE_OVD.id ? OVDOverviewCol : ITUOverviewCol}
              rows={overviewData}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

PublicDisplays.propTypes = {
  lruData: PropTypes.instanceOf(LRU).isRequired,
  aircraft: PropTypes.instanceOf(Aircraft)
}

export default PublicDisplays;
