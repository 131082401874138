import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { push } from 'react-router-redux';
import { SYSTEM } from 'constants';

import {
  FILTER_EVENT_TYPE_OPTIONS,
  LRU_TYPES,
  NOT_APPLICABLE,
  SELECT_OPTION_ALL,
  PATHS
} from 'constants';
import {
  getFlightHeaderText,
  getUTCDateTime,
} from 'utils';

import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import Overlay from 'containers/Overlay';
import SkyPulseTable from 'components/SkyPulseTable';
import ButtonItem from 'components/inputs/ButtonItem';
import ButtonList from 'components/inputs/ButtonList';
import EventTypeFilterOverlay from 'components/overlays/EventTypeFilterOverlay';
import LRUTypeFilterOverlay from './LRUTypeFilterOverlay';
import LRUFilterOverlay from './LRUFilterOverlay';

import styles from './styles.module.css';
import classnames from 'classnames';

const columns = [
  {
    name: 'UTC',
    key: 'utc',
    size: 'small',
    sortable: true,
    dataCyClassName: 'FlightEventsOverlay-FlightEventsTableColumn-Utc'
  },
  {
    name: 'LRU',
    key: 'lru',
    size: 'small',
    sortable: true,
    dataCyClassName: 'FlightEventsOverlay-FlightEventsTableColumn-Lru'
  },
  {
    name: 'Pos',
    key: 'pos',
    size: 'xsmall',
    sortable: true,
    dataCyClassName: 'FlightEventsOverlay-FlightEventsTableColumn-Pos'
  },
  {
    name: 'Event',
    key: 'event',
    sortable: true,
    dataCyClassName: 'FlightEventsOverlay-FlightEventsTableColumn-Event'
  }
];

const defaultFilters = {
  event_type: SELECT_OPTION_ALL.value,
};

const LRU_OPTION_AC = { id: '0', value: SYSTEM };
const sortedLRUTypes = [LRU_OPTION_AC]
  .concat(LRU_TYPES.getSortedEnumerationItems().map((item) => {
    const { id, value } = item;
    return { value, id };
  }));

sortedLRUTypes.forEach((item) => {
  defaultFilters[item.id] = 1;
});

const FlightEvents = (props) => {
  const {
    goBack,
    isOpen,
    onCancel,
    push,
    rActiveAircraft,
    rActiveFlight,
    rConfiguration,
  } = props;

  const reportsEnabled = rConfiguration.ff_reports;

  const eventsFilter = {};
  FILTER_EVENT_TYPE_OPTIONS.forEach((item) => {
    eventsFilter[item.value] = true;
  });

  const LRUFilters = {};
  sortedLRUTypes.forEach((item) => {
    LRUFilters[item.id] = true;
  });

  const [selectedEventFilter, setSelectedEventFilter] = useState('allEvents');
  const [selectedLRUFilter, setSelectedLRUFilter] = useState('allLRUs');
  const [currentEventsFilter, setCurrentEventsFilter] = useState(eventsFilter);
  const [currentLruFilter, setCurrentLruFilter] = useState(LRUFilters);
  const [currentLruPosFilter, setCurrentLruPosFilter] = useState(null);
  const [eventFilterOverlayCoordinates, setEventFilterOverlayCoordinates] = useState(null);
  const [lruFilterOverlayCoordinates, setLruFilterOverlayCoordinates] = useState(null);
  const [eventLruOverlay, setEventLruOverlay] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState(currentEventsFilter);

  const eventFilterButtonRef = useRef();
  const lruFilterButtonRef = useRef();

  const onEventFilterChange = (e) => {
    const { value } = e.target;
    setSelectedEventFilter(value);
    switch (value) {
      case 'allEvents':
        selectAllEvents();
        break;
      case 'allEventsMinusUsage':
        selectAllMinusUsage();
        break;
      case 'specificEvents':
        selectSpecificEvents();
        break;
      default:
        break;
    }
  }

  const onLRUFilterChange = (e) => {
    const { value } = e.target;
    setSelectedLRUFilter(value);
    switch (value) {
      case 'allLRUs':
        selectAllLrus();
        break;
      case 'specificLRU':
        selectSpecificLRU();
        break;
      case 'lruTypesPos':
      default:
        break;
    }
  }

  const selectAllEvents = () => {
    const newFilters = {};
    FILTER_EVENT_TYPE_OPTIONS.forEach((item) => {
      newFilters[item.value] = true;
    });
    setCurrentEventsFilter(newFilters);
  }

  const selectAllMinusUsage = () => {
    const newFilters = {};
    FILTER_EVENT_TYPE_OPTIONS.forEach((item) => {
      if (item.value !== '5') {
        newFilters[item.value] = true;
      }
    });
    setCurrentEventsFilter(newFilters);
  }

  const selectSpecificEvents = () => {
    onEventFilterPickerOpen(eventFilterButtonRef);
  }

  const onEventFilterPickerOpen = (ref) => {
    const { left, top } = ref.current.getBoundingClientRect();
    setEventFilterOverlayCoordinates({ x: left, y: top });
  }

  const renderSelectedEvents = () => {
    if (selectedEventFilter !== 'specificEvents') {
      return '--';
    }
    const selectedEvents = FILTER_EVENT_TYPE_OPTIONS.filter((item) => currentEventsFilter[item.value]);
    return `${selectedEvents.length} selected`;
  }

  const onEventsFilterDone = (filters) => {
    const newFilters = {}
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        newFilters[key] = true;
      }
    });
    setCurrentEventsFilter(newFilters);
    setEventFilterOverlayCoordinates(null);
    setSelectedEventFilter('specificEvents')
  }

  const selectAllLrus = () => {
    const newFilters = {};
    sortedLRUTypes.forEach((item) => {
      newFilters[item.id] = true;
    });
    setCurrentLruFilter(newFilters);
  }

  const selectSpecificLRU = () => {
    onLruFilterPickerOpen(lruFilterButtonRef);
  }

  const onLruFilterPickerOpen = (ref) => {
    const { left, top } = ref.current.getBoundingClientRect();
    setLruFilterOverlayCoordinates({ x: left, y: top });
  }

  const renderSelectedLru = () => {
    if (selectedLRUFilter !== 'specificLRU') {
      return '--';
    }
    const selectedLrus = sortedLRUTypes.filter((item) => currentLruFilter[item.id]);
    return selectedLrus.map((item) => item.value).join(', ');
  }

  const onLruFilterDone = (filters) => {
    const newFilters = {}
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        newFilters[key] = true;
      }
    });
    setCurrentLruFilter(newFilters);
    setLruFilterOverlayCoordinates(null);
    setSelectedLRUFilter('specificLRU');
  }

  const onAddFilter = (type, pos) => {
    setCurrentLruPosFilter({
      lruType: type,
      lruPos: pos
    });
    setEventLruOverlay(null);
    setSelectedLRUFilter('lruTypesPos');
  }

  const onRemoveFilter = () => {
    setCurrentLruPosFilter(null);
    setEventLruOverlay(null);
    setSelectedLRUFilter('allLRUs');
  }

  if (!rActiveFlight) {
    goBack();
    return null;
  }

  const { events } = rActiveFlight;

  const filteredEvents = events.filter((e) => {
    let eventCon = selectedEventFilter === 'allEvents';
    let lruCon = selectedLRUFilter === 'allLRUs';
    if (!eventCon) {
      eventCon = selectedEventFilter === 'allFaults'
        ? e.event_type.value.is_fault : currentEventsFilter[e?.event_type?.id];
    }
    if (!lruCon) {
      if (currentLruPosFilter && selectedLRUFilter === 'lruTypesPos') {
        lruCon = e.lru_type === currentLruPosFilter.lruType && e.lru_position === currentLruPosFilter.lruPos;
      } else {
        const id = e.lru_type && e.lru_type.id ? e.lru_type.id : '0'
        lruCon = currentLruFilter[id];
      }
    }
    if (eventCon && lruCon) {
      return e;
    }
    return null;
  })

  const data = filteredEvents.map((event) => {
    const {
      created,
      event_type,
      lru_position,
      lru_type,
      message,
    } = event;
    const { value: { default_message: defaultMessage } } = event_type;
    const lruTypeName = (lru_type) ? LRU_TYPES[lru_type.id] : SYSTEM;
    const lruPosition = lru_position || NOT_APPLICABLE;
    const displayMessage = message || defaultMessage;
    return {
      id: event.id,
      utc: { display: getUTCDateTime(created), sort: created },
      lru: lruTypeName,
      pos: lruPosition,
      event: displayMessage,
    }
  });

  const EventFilterOptions = [
    { name: 'All events', value: 'allEvents' },
    { name: 'All events minus usage', value: 'allEventsMinusUsage' },
    { name: 'All Faults', value: 'allFaults' },
    {
      name:
        <div>
          Specific events
          <Button
            data-cy-id="FlightEventsOverlay-eventsFilterButton"
            ref={eventFilterButtonRef}
            onClick={() => {
              onEventFilterPickerOpen(eventFilterButtonRef)
            }}
            className={classnames('button_reg', 'ml-2', styles.eventsFilterButton)}
          >
            {renderSelectedEvents()}
          </Button>
        </div>,
      value: 'specificEvents'
    },
  ];

  const LRUFilterOptions = [
    { name: 'All LRUs', value: 'allLRUs' },
    {
      name: <span>
        LRUs types
        <Button
          data-cy-id="FlightEventsOverlay-lrusFilterButton"
          ref={lruFilterButtonRef}
          onClick={() => {
            onLruFilterPickerOpen(lruFilterButtonRef)
          }}
          className={classnames('button_reg', 'ml-2', styles.lruFilterButton)}
        >
          {renderSelectedLru()}
        </Button>
      </span>,
      value: 'specificLRU'
    },
  ]

  return (
    <>
      <Overlay
        data-cy-id={`Flight-Events-Overlay`}
        contentClassName={styles.overlayContainer}
        isOpen={isOpen}
        // title={`Tail ${rActiveAircraft.tail} | ${getFlightHeaderText(rActiveFlight)}`}
        showClose={false}
        dialogClassName={styles.dialog}
        bodyClassName={styles.bodyClassName}
        onCancel={() => {
          onCancel();
        }}
      >
        <Row className={styles.root} noGutters>
          <Col className="h-100">
            <Row className={styles.headerRow} noGutters>
              <Col data-cy-id="FlightEventsOverlay-Header" className={styles.headerContainer}>
                Tail {rActiveAircraft.tail} | {getFlightHeaderText(rActiveFlight)}
              </Col>
              <Col md="auto">
                {
                  ((!rActiveFlight.is_open && reportsEnabled) ?
                    (<Button
                      className={`button_reg ${styles.headerButton}`}
                      data-cy-id="FlightReportButton" onClick={() => push(`${PATHS.reportFlight}/${rActiveFlight.id}`)}
                    >
                      Flight reports
                    </Button>)
                    : null)
                }
              </Col>
              <Col md="auto">
                <Button data-cy-id="FlightEventsOverlay-CloseButton" className={`button_foc ${styles.headerButton}`} onClick={() => onCancel()}>Close</Button>
              </Col>
            </Row>
            <Row className={styles.filterRow} noGutters>
              <Col md="auto">
                <ButtonList
                  key={selectedEventFilter}
                  idPrefix="Events-filter"
                  name="eventFilters"
                  type="radio"
                  onChange={onEventFilterChange}
                  options={EventFilterOptions}
                  value={selectedEventFilter}
                  arrangementClassName={styles.filterList}
                  classname={styles.options}
                  testIdPrefix="FlightEventsOverlay"
                />
              </Col>
              <Col md="auto" className={styles.divider} />
              <Col md="auto">
                <ButtonList
                  key={selectedLRUFilter}
                  idPrefix="LRUs-filter"
                  name="LRUFilters"
                  type="radio"
                  onChange={onLRUFilterChange}
                  options={LRUFilterOptions}
                  value={selectedLRUFilter}
                  arrangementClassName={styles.filterList}
                  classname={styles.options}
                  testIdPrefix="FlightEventsOverlay"
                />
              </Col>
              {!currentLruPosFilter
                ? null
                : (
                  <Col md="auto">
                    <ButtonItem
                      name="LRUFilters"
                      label={
                        <div>
                          {`${currentLruPosFilter.lruType.value} ${currentLruPosFilter.lruPos}`}
                          <Button
                            onClick={onRemoveFilter}
                            className={classnames('button_reg', 'ml-2', styles.removeButton)}
                          >
                            Remove filter
                          </Button>
                        </div>
                      }
                      classname="d-flex align-items-center"
                      value="lruTypesPos"
                      type="radio"
                      onChange={onLRUFilterChange}
                      labelClassName={styles.buttonItemLabel}
                      isChecked={selectedLRUFilter === 'lruTypesPos'}
                      idPrefix={'lru-types'}
                    />
                  </Col>
                )}
            </Row>
            <Row className={styles.tableRow} noGutters>
              <Col className={styles.tableContainer}>
                <SkyPulseTable
                  columns={columns}
                  rows={data}
                  allowExport={!(reportsEnabled)}
                  title={rActiveFlight.is_open
                    ? "Flight events"
                    : (
                      <Row>
                        <Col> Flight events </Col>
                      </Row>
                    )
                  }
                  defaultSort="utc"
                  defaultSortState="ascending"
                  onRowClick={(id) => {
                    const event = events.find((event) => event.id === id);
                    if (event?.lru_type) {
                      setEventLruOverlay(event);
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Overlay>
      <EventTypeFilterOverlay
        position={eventFilterOverlayCoordinates}
        isOpen={(eventFilterOverlayCoordinates !== null)}
        onCancel={() => setEventFilterOverlayCoordinates(null)}
        onDone={onEventsFilterDone}
        selectedEvents={selectedEvents}
        setSelectedEvents={(filters) => setSelectedEvents(filters)}
      />
      <LRUTypeFilterOverlay
        position={lruFilterOverlayCoordinates}
        isOpen={(lruFilterOverlayCoordinates !== null)}
        onCancel={() => setLruFilterOverlayCoordinates(null)}
        onDone={onLruFilterDone}
        filters={currentLruFilter} />
      <LRUFilterOverlay
        isOpen={eventLruOverlay !== null}
        lrusAndFaultsEnabled={rConfiguration.ff_lrus_and_faults}
        onClose={() => setEventLruOverlay(null)}
        onAddFilter={onAddFilter}
        onRemoveFilter={onRemoveFilter}
        event={eventLruOverlay}
        isFilterAdded={currentLruPosFilter !== null}
        push={push}
      />
    </>
  );
}

const state = (state) => {
  return ({
    rActiveAircraft: state.activeAircraft,
    rActiveFlight: state.activeFlight,
    rConfiguration: state.configuration,
  });
};

const actions = {
  goBack,
  push
};

export default connect(state, actions)(FlightEvents);
